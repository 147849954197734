<template>
  <pageBack>
    <div class="main">
      <p style="background-color:#ffffff; margin:8pt 0pt 32pt; text-align:center">
        <span
          style="color:#333333;  font-size:16.5pt; font-weight:bold"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:16.5pt; font-weight:bold"
        >
          Membership Agreement</span
        >
      </p>
      <p style="margin:12.75pt 0pt 9pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M4"></a
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY! PLEASE PAY
          ATTENTION TO PROVISIONS THAT EXCLUDE OR LIMIT LIABILITY</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >AND T</span
        ><span
          style=" font-size:9pt; font-weight:bold"
          >ERMS OF GOVERNING LAW AND JURISDICTION, WHICH MAY APPEAR IN CAPITAL
          LETTERS.</span
        ><a name="_DV_C3"
          ><span
            style=" font-size:9pt; font-weight:bold"
            >&#xa0;</span
          ><span
            style=" font-size:9pt; font-weight:bold"
            >THIS AGREEMENT RELATES TO</span
          ><span
            style=" font-size:9pt; font-weight:bold"
          >
          </span
          ><span
            style=" font-size:9pt; font-weight:bold"
            >.</span
          ></a
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M5"></a
        ><a name="_DV_M6"><span style="-aw-bookmark-end:_DV_M5"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >1.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
          ACCEPTANCE OF TERMS</span
        ><a name="_DV_M7"></a
        ><a name="_DV_M8"><span style="-aw-bookmark-end:_DV_M7"></span></a>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >1.1</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          WELCOME TO </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          SERVICE (the "SERVICE"). </span
        ><span
          style="color:#333333;  font-size:9pt"
          >You must be registered on</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          the FunPinPin platform</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to access or use some Services of FunPinPin.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >The following sets forth the terms and conditions of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Membership Agreement (this "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Agreement</span
        ><span
          style="color:#333333;  font-size:9pt"
          >") between you (“</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”) and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >HONGKONG XUNHAI TECHNOLOGY LIMITED</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, with its registered office at</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          FLAT/RM A 12/F KIU FU COMMERCIAL BLDG, 300 LOCKHART ROAD, WAN CHAI
          HK</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >("</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >") under which </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          offers you access to and use of the Service through the websites,
          mobile sites, mobile applications and other portals</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >owned, operated, branded or made available by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to you from time to time relating to (a) the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          platform including but not limited to the web and mobile-optimized
          versions of the websites identified by the uniform resource locator
          “</span
        ><span
          style="color:#333333;  font-size:9pt"
          >funpinpin.com</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, “funpinpin.store”</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and the mobile applications of the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          platform (collectively the “</span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites”</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or the “Sites”</span
        ><span
          style="color:#333333;  font-size:9pt"
          >) </span
        ><a name="_DV_C19"></a><a name="_DV_M15"></a><a name="_DV_C20"></a
        ><a name="_DV_M16"></a><a name="_DV_C21"></a><a name="_DV_M17"></a
        ><a name="_DV_M18"
          ><span style="-aw-bookmark-end:_DV_C19"></span
          ><span style="-aw-bookmark-end:_DV_M15"></span
          ><span style="-aw-bookmark-end:_DV_C20"></span
          ><span style="-aw-bookmark-end:_DV_M16"></span
          ><span style="-aw-bookmark-end:_DV_C21"></span
          ><span style="-aw-bookmark-end:_DV_M17"></span
        ></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M19"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >As some or part of the Service may be supported and provided by
          affiliates of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may delegate some aspects of the Service to its affiliates. Use of the
          Service indicates that you accept the terms and conditions set forth
          below. If you do not accept all of the terms and conditions, please do
          not use the Service. BY COMPLETING THE REGISTRATION PROCESS AND
          CLICKING THE "I AGREE" BUTTON, YOU ARE INDICATING YOUR CONSENT TO BE
          BOUND BY THIS AGREEMENT, AND PRIVACY POLICY (DEFINED BELOW) WHICH ARE
          INCORPORATED HEREIN BY REFERENCE (COLLECTIVELY REFERRED TO AS THE
          "TERMS OF USE"). The </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Membership Agreement will not take effect unless and until you have
          activated your Account. Terms not defined in this Agreement shall bear
          the same meaning as that contained in the Terms of Use.</span
        ><a name="_DV_M22"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M23"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >1.2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >FUNPINPIN MAY AMEND THIS AGREEMENT AT ANY TIME BY POSTING THE AMENDED
          AND RESTATED AGREEMENT ON THE SITES. THE AMENDED AND RESTATED
          AGREEMENT SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING. POSTING BY
          FUNPINPIN OF THE AMENDED AND RESTATED AGREEMENT AND YOUR CONTINUED USE
          OF THE SERVICE SHALL BE DEEMED TO BE ACCEPTANCE OF THE AMENDED
          TERMS.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M24"></a
        ><a name="_DV_M25"><span style="-aw-bookmark-end:_DV_M24"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >2.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
          THE SERVICE</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M26"></a
        ><a name="_DV_M27"><span style="-aw-bookmark-end:_DV_M26"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.1</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >The Service will be offered </span
        ><span
          style="color:#333333;  font-size:9pt"
          >in the following models</span
        ><span
          style="color:#333333;  font-size:9pt"
          >for an unspecified time period unless terminated in accordance with
          the terms of this Agreement.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >a)</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          If y</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ou </span
        ><span
          style="color:#333333;  font-size:9pt"
          >chose</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to use </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >’</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          secondary domain name</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, you will have a FunPinPin online store, or</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >b) If you chose to creat</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          your own </span
        ><span
          style="color:#333333;  font-size:9pt"
          >p</span
        ><span
          style="color:#333333;  font-size:9pt"
          >rimary</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >domain name</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >you will have</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          an</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >independent ecommerce site</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >Whichever </span
        ><span
          style="color:#333333;  font-size:9pt"
          >model </span
        ><span
          style="color:#333333;  font-size:9pt"
          >you</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may chose</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          above, you will get a </span
        ><span
          style="color:#333333;  font-size:9pt"
          >“</span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >2.2</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin reserves the right to require</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          a</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Guarantee</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          deposit</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, to make sure</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          that Member will comply with its obligations </span
        ><span
          style="color:#333333;  font-size:9pt"
          >hereunder, especially its obligation to deliver </span
        ><span
          style="color:#333333;  font-size:9pt"
          >items ordered by third party or the items delivered </span
        ><span
          style="color:#333333;  font-size:9pt"
          >to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >meet the terms and descriptions outlined in its contract with such
          third party</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M28"></a
        ><a name="_DV_M29"><span style="-aw-bookmark-end:_DV_M28"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >3</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >The Service will have the following core features (which may be added
          to or modified, or suspended for scheduled or unscheduled maintenance
          purposes, from time to time at the sole discretion of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and notified to you)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >:</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M30"></a
        ><a name="_DV_M31"><span style="-aw-bookmark-end:_DV_M30"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >a) </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Creat</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          you</span
        ><span
          style="color:#333333;  font-size:9pt"
          >r</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          own </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          – allows</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          each Member to creat</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          a </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >for your business and to decorate your </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          with temples</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and materials</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          available</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          on the Sites</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >b</span
        ><a name="_DV_M32"></a
        ><a name="_DV_M33"><span style="-aw-bookmark-end:_DV_M32"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><a name="_DV_M34"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >Products - allows each Member to display and edit descriptions,
          specifications and images of</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          its</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          products.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >c) </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Online </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Transaction</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          –provide</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          online  platform</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          that allows</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          each Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to accept, conclude, manage and fulfill orders</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (to sell its </span
        ><span
          style="color:#333333;  font-size:9pt"
          >products</span
        ><span
          style="color:#333333;  font-size:9pt"
          >)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M35"></a><a name="_DV_M36"></a><a name="_DV_M37"></a
        ><a name="_DV_M38"
          ><span style="-aw-bookmark-end:_DV_M35"></span
          ><span style="-aw-bookmark-end:_DV_M36"></span
          ><span style="-aw-bookmark-end:_DV_M37"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >4 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may suspend or terminate all or part of the above </span
        ><span
          style="color:#333333;  font-size:9pt"
          >features</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          at any time in its sole discretion without liability for any losses or
          damages arising out of or in connection with such suspension or
          termination. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Also, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to charge for the Service or any feature or
          functionality of the Service </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          change the price thereof </span
        ><span
          style="color:#333333;  font-size:9pt"
          >at any time in its sole discretion.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M40"></a
        ><a name="_DV_M41"><span style="-aw-bookmark-end:_DV_M40"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >5</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >No warranty or representation is given that a particular feature or
          function or the same type and extent of features and functions will be
          available.</span
        ><a name="_DV_M42"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M43"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >6</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >The availability of any transactional features and functions on the
          Sites to any Member may be conditional on verification of Member’s
          identity.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M44"></a
        ><a name="_DV_M45"><span style="-aw-bookmark-end:_DV_M44"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.7 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall issue a Member </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Username </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and Password (the latter shall be </span
        ><span
          style="color:#333333;  font-size:9pt"
          >changed </span
        ><span
          style="color:#333333;  font-size:9pt"
          >by the Member) to each Member to access the Service through such
          Member's Account. Each Member shall be solely responsible for
          maintaining the confidentiality of its </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and Password and for all use of and activities that occur under the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and Password (whether such use or activities are authorized or not). A
          set of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and Password is unique to a single Account and no Member shall share,
          assign or permit the use of its Account, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or Password to another person, even to other individuals within the
          Member's own business entity (where applicable). Each Member
          acknowledges that sharing of its Account with other persons, or
          allowing multiple </span
        ><span
          style="color:#333333;  font-size:9pt"
          >third parties</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >outside of its business entity to use its Account (collectively,
          "multiple use"), may cause irreparable harm to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and each Member shall indemnify </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          against any loss or damages (including but not limited to loss of
          profits) suffered by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          as a result of such multiple use of an Account. Each Member hereby
          undertakes to notify </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          immediately of any unauthorized use of its Account, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or Password or any other breach of security. Each Member hereby agrees
          that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall not be liable for any loss or damages arising from the Member's
          failure to comply with this paragraph.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M46"></a
        ><a name="_DV_M47"><span style="-aw-bookmark-end:_DV_M46"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to change, upgrade, modify, limit or suspend the
          Service or any of its related functionalities or applications at any
          time temporarily or permanently without prior notic. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          further reserves the right but shall not be obliged to introduce new
          features, functionalities, applications or conditions to the Service
          or to future versions of the Service. All new features,
          functionalities, applications, conditions, modifications, upgrades and
          alterations shall be governed by this Agreement, unless otherwise
          stated by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.
        </span>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M48"></a
        ><a name="_DV_M49"><span style="-aw-bookmark-end:_DV_M48"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >9</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member acknowledges that inability to use the Service wholly or
          partially for whatever reason may have adverse effects on its
          business. Each Member hereby agrees that in no event shall </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          be liable to the Member or any third parties for any inability to use
          the Service (whether due to disruption, changes to or termination of
          the Service or otherwise), any delays, inaccuracies, errors or
          omissions with respect to any communications or transmission or
          delivery of all or any part thereof, or any losses or damages (direct,
          indirect, consequential or otherwise) arising from the use of or
          inability to use the Service.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M50"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >10</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Y</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ou hereby represent and agree to authorize </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to provide all the information associated with your </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member Username</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites for the purpose of facilitating your easy and fast access to the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right, without prior notice and at its sole discretion,
          to suspend, restrict or deny access to or use of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >the</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >S</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ervices provided by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites without liability for any losses or damages arising out of or in
          connection with such suspension or termination, if you: (a) use the
          services provided by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, or through the Sites and the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites to defraud any person or entity; (b) engage in any unlawful
          activities including without limitation those which would constitute
          the infringement of intellectual property rights, a civil liability or
          a criminal offence; (c) engage in any activities that would otherwise
          create any liability for </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Sites.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M52"></a
        ><a name="_DV_M53"><span style="-aw-bookmark-end:_DV_M52"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >2.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >11 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >If you voluntarily submit any information to the Sites for
          publication on the Sites through the publishing tools, including but
          not limited to, company or business profile, product catalog, trade
          leads and any discussion forum, then you are deemed to have given
          consent to the publication of such information on the Sites.</span
        ><a name="_DV_M54"></a>
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M55"></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >3.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
        </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >MEMBER RESPONSIBILITIES</span
        ><a name="_DV_M56"></a
        ><a name="_DV_M57"><span style="-aw-bookmark-end:_DV_M56"></span></a>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >1</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member will be required to provide information or material about your
          entity, business or products/services as part of the registration
          process on the Sites for your access to and use of any Service or the
          member account.  Each Member represents, warrants and agrees that (a)
          such information and material whether submitted during the
          registration process or thereafter </span
        ><span
          style="color:#333333;  font-size:9pt"
          >as may be required by FunPinPin </span
        ><span
          style="color:#333333;  font-size:9pt"
          >throughout the continuation of the use of the Sites or Service, is
          true, accurate, current and complete, and (b) you will maintain and
          promptly amend all information and material to keep it true, accurate,
          current and complete.
        </span>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member hereby represents, warrants and agrees</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >(a) </span
        ><span
          style="color:#333333;  font-size:9pt"
          >you have full power and authority to accept the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Agreement</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, to grant the license and authorization and to perform the
          obligations hereunder; (</span
        ><span
          style="color:#333333;  font-size:9pt"
          >b</span
        ><span
          style="color:#333333;  font-size:9pt"
          >) your access and use the Sites and Services will be for business
          purposes only</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >To the extent permitted under applicable laws</span
        ><span style=" font-size:9pt">,</span
        ><span style=" font-size:9pt">&#xa0;</span
        ><a name="_DV_C26"
          ><span style=" font-size:9pt"
            >each</span
          ></a
        ><a name="_DV_M58"><span style="-aw-bookmark-end:_DV_C26"></span></a
        ><span style=" font-size:9pt">&#xa0;</span
        ><span style=" font-size:9pt"
          >Member hereby grants an irrevocable, perpetual, worldwide and
          royalty-free, sub-licensable (through multiple tiers) license to </span
        ><span style=" font-size:9pt"
          >FunPinPin</span
        ><span style=" font-size:9pt">
          to display and use all information provided by such Member in
          accordance with the purpos</span
        ><span
          style="color:#333333;  font-size:9pt"
          >es set forth in this Agreement and to exercise the copyright,
          publicity, and database rights you have in such material or
          information, in any media now known or not currently known.
          Information that is protected under data protection laws will only be
          used and kept in compliance with those laws.</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >UPON BECOMING A MEMBER, YOU CONSENT TO THE INCLUSION OF THE CONTACT
          INFORMATION ABOUT YOU IN OUR DATABASE AND AUTHORIZE FUNPINPIN AND ITS
          AFFILIATES TO SHARE THE CONTACT INFORMATION WITH OTHER USERS OR
          OTHERWISE USE YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THE PRIVACY
          POLICY.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M59"></a
        ><a name="_DV_M60"><span style="-aw-bookmark-end:_DV_M59"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >3</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member hereby represents, warrants and agrees that the use by
          such Member of the Service and the Sites shall not:</span
        ><a name="_DV_M61"></a
        ><a name="_DV_M62"><span style="-aw-bookmark-end:_DV_M61"></span></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >a) contain fraudulent information or make fraudulent offers of items
          or involve the sale or attempted sale of counterfeit or stolen items
          or items whose sales and/or marketing is prohibited by applicable law,
          or otherwise promote other illegal activities;</span
        ><a name="_DV_M63"></a
        ><a name="_DV_M64"><span style="-aw-bookmark-end:_DV_M63"></span></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >b) be part of a scheme to defraud other Members or other users of the
          Sites or for any other unlawful purpose;</span
        ><a name="_DV_M65"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M66"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >c) infringe or otherwise abet or encourage the infringement or
          violation of any third party's copyright, patent, trademarks, trade
          secret or other proprietary right or rights of publicity and privacy
          or other legitimate rights;</span
        ><a name="_DV_M67"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M68"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >d) impersonate any person or entity, misrepresent yourself or your
          affiliation with any person or entity;</span
        ><a name="_DV_M69"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M70"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >e) violate any applicable law, statute, ordinance or regulation
          (including without limitation those governing export control, consumer
          protection, unfair competition, anti-discrimination or false
          advertising);</span
        ><a name="_DV_M71"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M72"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >f) contain information that is defamatory, libelous, unlawfully
          threatening or unlawfully harassing;</span
        ><a name="_DV_M73"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M74"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >g) contain information that is obscene or contain or infer any
          pornography or sex-related merchandising or any other content or
          otherwise promotes sexually explicit materials or is otherwise harmful
          to minors;</span
        ><a name="_DV_M75"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M76"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >h) promote discrimination based on race, sex, religion, nationality,
          disability, sexual orientation or age;</span
        ><a name="_DV_M77"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M78"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >i) contain any material that constitutes unauthorized advertising or
          harassment (including but not limited to spamming), invades anyone's
          privacy or encourages conduct that would constitute a criminal
          offence, give rise to civil liability, or otherwise violate any law or
          regulation;</span
        ><a name="_DV_M79"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M80"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >j) involve attempts to copy, reproduce, exploit or expropriate </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >'s various proprietary directories, databases and listings;</span
        ><a name="_DV_M81"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M82"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >k) involve any computer viruses or other destructive devices and
          codes that have the effect of damaging, interfering with, intercepting
          or expropriating any software or hardware system, data or personal
          information; and</span
        ><a name="_DV_M83"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M84"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >l) involve any scheme to undermine the integrity of the computer
          systems or networks used by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and/or any user of the Service and no Member shall attempt to gain
          unauthorized access to such computer systems or networks;</span
        ><a name="_DV_M85"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M86"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >m) link directly or indirectly to or include descriptions of goods or
          services or other materials that violate any law or regulation or are
          prohibited under this Agreement or the Terms of Use; or</span
        ><a name="_DV_M87"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M88"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >n) otherwise create any liability for </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or its affiliates.</span
        ><a name="_DV_M89"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M90"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >4 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member represents, warrants and agrees that with regards to
          information about or posted on behalf of any business referee, it has
          obtained all necessary consents, approvals and waivers from such
          referee to (a) act as such Member's business referee; (b) to post and
          publish their contact details and information</span
        ><span
          style="color:#333333;  font-size:9pt"
          >,</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >that third parties may contact such business referees to support
          claims or statements made about the Member. Each Member hereby waives
          all requirements for such Member's consent to be obtained before third
          parties may contact the business referees.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M91"></a><a name="_DV_M92"></a><a name="_DV_M93"></a
        ><a name="_DV_M94"
          ><span style="-aw-bookmark-end:_DV_M91"></span
          ><span style="-aw-bookmark-end:_DV_M92"></span
          ><span style="-aw-bookmark-end:_DV_M93"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >5</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member acknowledges and agrees that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to, but shall not be required to actively monitor
          nor exercise any editorial control whatsoever over the content of any
          message or other material or information created, obtained or
          accessible through the Service. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          does not endorse, verify or otherwise certify the contents of any
          comments or other material or information made by any Member. Each
          Member is solely responsible for the contents of their communications
          and may be held legally liable or accountable for the content of their
          material or information.</span
        ><a name="_DV_M95"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M96"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.6 Each Member represents, warrants and agrees that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >(a)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >it has obtained all necessary third party licenses and permissions
          and shall be solely responsible for ensuring that any material or
          information it posts on the Sites or provides to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or authorizes </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to display does not violate the copyright, patent, trademark, trade
          secrets or any other personal or proprietary rights of any third party
          or is posted with the permission of the owner(s) of such rights. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >(b)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >it has the right and authority to sell, distribute</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, export</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or offer to sell</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >distribute the products described in the material or information it
          posts on the Sites or provides to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or authorizes </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to display.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >(c) it is not the subject of any trade restrictions, sanctions or
          other legal restrictions enacted by any country, international
          organization or jurisdiction.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M97"></a
        ><a name="_DV_M98"><span style="-aw-bookmark-end:_DV_M97"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >7</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >If any Member breaches the representations, warranties and covenants
          of paragraphs 3.1, 3.2, 3.3, 3.4, 3.5 or 3.6 above, or if </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          has reasonable grounds to believe that such Member is in breach of
          such representations, warranties and covenants, or if upon complaint
          or claim from any other Member or third party, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          has reasonable grounds to believe that such Member has willfully or
          materially failed to perform its contract with such third party
          including without limitation where a Member who supplies products or
          services using the Sites and Services has failed to deliver any items
          ordered by such third party after receipt of the purchase price, or
          where such Member has delivered the items that materially fail to meet
          the terms and descriptions outlined in its contract with such third
          party, or if </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          has reasonable grounds to believe that such Member has used a stolen
          credit card or other false or misleading information in any online
          transaction, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          has the right to</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >suspend or terminate </span
        ><span
          style="color:#333333;  font-size:9pt"
          >the Service and all </span
        ><span
          style="color:#333333;  font-size:9pt"
          >features</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          with respect to such Member without compensation and without liability
          for any losses or damages arising out of or in connection with such
          suspension or termination, and restrict or refuse any and all current
          or future use of the Service or any other services that may be
          provided by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. Further, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right in its sole discretion to place restrictions on the
          number of product listings that a Member can post on the Sites for
          such duration as </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may consider appropriate, and to remove any material it reasonably
          believes that is unlawful, could subject </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to liability, violates this Agreement or the Terms of Use or is
          otherwise found inappropriate in </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >'s opinion. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to cooperate fully with governmental and regulatory
          authorities, law enforcement bodies, private investigators and/or
          injured third parties in the investigation of any suspected criminal
          or civil wrongdoing. Further, to the extent permitted under applicable
          laws and policies, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may disclose the Member's identity, contact information and/or
          information regarding the Member's account(s), transactions or
          activities carried out on or via the Sites, if requested by a
          government, regulatory or law enforcement body or an injured third
          party, or as a result of a subpoena or other legal action, and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall not be liable for damages or results thereof and Member agrees
          not to bring any action or claim against </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          for such disclosure. In connection with any of the foregoing, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may suspend or terminate the Account of any Member as </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          deems appropriate in its sole discretion.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M99"></a
        ><a name="_DV_M100"><span style="-aw-bookmark-end:_DV_M99"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >3.8 Each Member agrees to indemnify </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, its employees, agents and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >affiliates </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and to hold them harmless, from any and all losses, claims and
          liabilities (including legal costs on a full indemnity basis) which
          may arise from its submissions, posting of materials or deletion
          thereof, from such Member's use of the Service or from such Member's
          breach of this Agreement or the Terms of Use. Each Member further
          agrees that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          is not responsible, and shall have no liability to it or anyone else
          for any material posted by such Member or third parties, including
          fraudulent, untrue, misleading, inaccurate, defamatory, offensive or
          illicit material and that the risk of damage from such material rests
          entirely with each Member. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the righ</span
        ><span
          style="color:#333333;  font-size:9pt"
          >t and</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          at its own expense, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by the Member, in which
          event the Member shall cooperate with </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          in asserting any available defenses.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >3.9 Member may not use the Sites, Services or member account to
          engage in activities which are identical or similar to the
          Service.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >3.10 Member acknowledges and agrees that each Member is solely
          responsible for observing applicable laws and regulations in its
          respective jurisdictions to ensure that all access and use of the Site
          and Services are in compliance with the same.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >3.11   Member agrees to pay and be responsible for any taxes, duties,
          levies or charges for the use of the</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          transaction</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ervice. In the event </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          is required by any applicable law to collect or withhold any taxes or
          duties,</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          agree</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to pay such taxes or duties to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >You shall be solely responsible for payment of any taxes (such as
          VAT), duties or other governmental levies or any charges or fees that
          may be imposed on any products or services purchased or supplied under
          or in connection with the Online Transactions.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M101"></a
        ><a name="_DV_M102"><span style="-aw-bookmark-end:_DV_M101"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >4.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
        </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >TRANSACTIONS BETWEEN BUYERS AND </span
        ><a name="_DV_M103"></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >MEMBERS</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M104"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.1 Through the Sites, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          provides an electronic web-based platform for exchanging information
          and concluding sale and purchase transactions of products and services
          online between buyers and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Members</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to limit certain features and functions of the
          platform to prescribed Members.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Despite the provision of the platform through the Sites, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          does not represent </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member (</span
        ><span
          style="color:#333333;  font-size:9pt"
          >the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Seller</span
        ><span
          style="color:#333333;  font-size:9pt"
          >)</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or the buyer in specific transactions whether or not such transactions
          are made on or via the Sites. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          does not control and is not liable to or responsible for the quality,
          safety, lawfulness or availability of the products or services offered
          for sale on the Sites or the ability of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Members</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to complete a sale or the ability of buyers to complete a
          purchase.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M105"></a
        ><a name="_DV_M106"><span style="-aw-bookmark-end:_DV_M105"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.2 Members are hereby made aware that there may be risks of dealing
          with people acting under false pretences. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          uses several techniques to verify the accuracy of the information our
          users provide us when they register on the Sites. However, because
          user verification on the Internet is difficult, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          cannot and does not confirm each member's purported identity shown on
          the Sites</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M107"></a
        ><a name="_DV_M108"><span style="-aw-bookmark-end:_DV_M107"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.3 Each Member accessing or using the Sites or Service shall fully
          assume the risks of conducting any purchase and sale transactions in
          connection with or through the Sites or Service. Such risks shall
          include, but not limited to, mis-representation of products and
          services, fraudulent schemes, unsatisfactory quality, failure to meet
          specifications, defective products, delay or default in delivery or
          payment, cost mis-calculations, breach of warranty, breach of contract
          and transportation accidents ("Transaction Risks"). Each Member agrees
          that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall not be liable or responsible for any damages, liabilities,
          costs, harms, inconveniences, business disruptions or expenditures of
          any kind that may arise a result of or in connection with any
          Transaction Risks.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M109"></a
        ><a name="_DV_M110"><span style="-aw-bookmark-end:_DV_M109"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.4 Members are solely responsible for setting out, agreeing on,
          entering into and performing all of the terms and conditions of the
          transactions conducted on, through or as a result of use of the Sites,
          including, without limitation, terms regarding payment, returns,
          warranties, shipping, insurance, fees, taxes, title, licenses, fines,
          permits, handling, transportation and storage, subject to any
          additional obligations impose</span
        ><span
          style="color:#333333;  font-size:9pt"
          >d hereunder</span
        ><a name="_DV_M111"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M112"></a
        ><a name="_DV_M113"><span style="-aw-bookmark-end:_DV_M112"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.5 Member agrees to provide all information and materials as may be
          reasonably required by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          in connection with its transactions made via the transactional
          platform on the Sites.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          has the right to suspend or terminate any Member’s Account if the
          Member fails to provide the required information and materials without
          liability for any losses or damages arising out of or in connection
          with such suspension or termination.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M117"></a
        ><a name="_DV_M118"><span style="-aw-bookmark-end:_DV_M117"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >4.6 In the event that any Member has a dispute with any party to a
          transaction, such Member agrees to release and indemnify </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (and our agents, affiliates, directors, officers and employees) from
          all claims, demands, actions, proceedings, costs, expenses and damages
          (including without limitation any actual, special, incidental or
          consequential damages) arising out of or in connection with such
          dispute or the transaction.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >4.7 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >In the event that Member temporarily shut down its </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          by removing all the products from the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >product listing</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or </span
        ><span
          style="color:#333333;  font-size:9pt"
          >its Account as a Member has been terminated</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (Collectively</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          “Store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Closure</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”)</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall continue to assume the transaction responsibilities such as
          delivery, return and replacement, quality assurance and maintenance, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and otherwise</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          for the transactions already concluded before </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Store Closure.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M119"></a
        ><a name="_DV_M120"><span style="-aw-bookmark-end:_DV_M119"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >5.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
        </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >USE OF THE SITE</span
        ><a name="_DV_M121"></a>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M122"></a><a name="_DV_M123"></a><a name="_DV_M124"></a
        ><a name="_DV_M125"></a
        ><a name="_DV_M126"
          ><span style="-aw-bookmark-end:_DV_M122"></span
          ><span style="-aw-bookmark-end:_DV_M123"></span
          ><span style="-aw-bookmark-end:_DV_M124"></span
          ><span style="-aw-bookmark-end:_DV_M125"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >1 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          reserves the right to, but shall have no obligation to, delete or edit
          any postings in its sole discretion without prior notice. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may but shall not be obliged to monitor posting activities of any
          Member who is in breach of this Agreement and may restrict their
          ability to post messages on the Sites. Under no circumstances will </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          be liable in any way for any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >content</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, including (without limitation) any errors or omissions in any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >content</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. Each Member agrees to evaluate and bear all risks associated with
          the use of any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >content</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          including any reliance on its accuracy or completeness.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M127"></a><a name="_DV_M128"></a><a name="_DV_M129"></a
        ><a name="_DV_M130"></a><a name="_DV_M131"></a><a name="_DV_M132"></a
        ><a name="_DV_M133"></a><a name="_DV_M134"></a><a name="_DV_M135"></a
        ><a name="_DV_M136"></a><a name="_DV_M137"></a><a name="_DV_M138"></a
        ><a name="_DV_M139"></a><a name="_DV_M140"></a><a name="_DV_M141"></a
        ><a name="_DV_M142"></a><a name="_DV_M143"></a><a name="_DV_M144"></a
        ><a name="_DV_M145"></a><a name="_DV_M146"></a><a name="_DV_M147"></a
        ><a name="_DV_M148"></a><a name="_DV_M149"></a
        ><a name="_DV_M150"
          ><span style="-aw-bookmark-end:_DV_M127"></span
          ><span style="-aw-bookmark-end:_DV_M128"></span
          ><span style="-aw-bookmark-end:_DV_M129"></span
          ><span style="-aw-bookmark-end:_DV_M130"></span
          ><span style="-aw-bookmark-end:_DV_M131"></span
          ><span style="-aw-bookmark-end:_DV_M132"></span
          ><span style="-aw-bookmark-end:_DV_M133"></span
          ><span style="-aw-bookmark-end:_DV_M134"></span
          ><span style="-aw-bookmark-end:_DV_M135"></span
          ><span style="-aw-bookmark-end:_DV_M136"></span
          ><span style="-aw-bookmark-end:_DV_M137"></span
          ><span style="-aw-bookmark-end:_DV_M138"></span
          ><span style="-aw-bookmark-end:_DV_M139"></span
          ><span style="-aw-bookmark-end:_DV_M140"></span
          ><span style="-aw-bookmark-end:_DV_M141"></span
          ><span style="-aw-bookmark-end:_DV_M142"></span
          ><span style="-aw-bookmark-end:_DV_M143"></span
          ><span style="-aw-bookmark-end:_DV_M144"></span
          ><span style="-aw-bookmark-end:_DV_M145"></span
          ><span style="-aw-bookmark-end:_DV_M146"></span
          ><span style="-aw-bookmark-end:_DV_M147"></span
          ><span style="-aw-bookmark-end:_DV_M148"></span
          ><span style="-aw-bookmark-end:_DV_M149"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member acknowledges that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          does not pre-screen </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent but that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall have the right (though not the obligation) in its sole
          discretion to move, modify or remove any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent that is posted</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          on the Sites.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M154"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >3</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >To the extent permitted under applicable laws,</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><a name="_DV_M155"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >each member grants to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          a perpetual, world-wide, royalty-free irrevocable, non-exclusive
          licence (including the right to sub-license through multiple tiers) to
          use, reproduce, modify, adapt, publish, translate, create derivative
          works from, distribute, perform and display any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent (in whole or part) such Member uploaded, posted or supplied to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          for posting on the Sites and/or to incorporate such </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent in other works in any form, media or technology now known or
          developed. Information that is protected under data protection laws
          will only be used and kept in compliance with those laws.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M156"></a
        ><a name="_DV_M157"><span style="-aw-bookmark-end:_DV_M156"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >4 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Each Member shall indemnify and hold </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and its subsidiaries, affiliates, employees, officers, agents or
          partners harmless from and against any direct or indirect loss or
          damage (including consequential loss and loss of profits, goodwill or
          business opportunities) arising from any third party claim in relation
          to any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent such Member uploaded, posted on the Sites.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M158"></a
        ><a name="_DV_M159"><span style="-aw-bookmark-end:_DV_M158"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >5 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >On being made aware of any such breaches, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may ban, delete or prohibit any </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent that relates to those breaches or that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          in its sole discretion consider to be harmful to the public or the
          rights of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or any of its affiliates, licensors, partners or Members.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M160"></a><a name="_DV_M161"></a><a name="_DV_M162"></a
        ><a name="_DV_M163"></a><a name="_DV_M164"></a><a name="_DV_M165"></a
        ><a name="_DV_M166"></a><a name="_DV_M168"></a><a name="_DV_M169"></a
        ><a name="_DV_M170"></a
        ><a name="_DV_M171"
          ><span style="-aw-bookmark-end:_DV_M160"></span
          ><span style="-aw-bookmark-end:_DV_M161"></span
          ><span style="-aw-bookmark-end:_DV_M162"></span
          ><span style="-aw-bookmark-end:_DV_M163"></span
          ><span style="-aw-bookmark-end:_DV_M164"></span
          ><span style="-aw-bookmark-end:_DV_M165"></span
          ><span style="-aw-bookmark-end:_DV_M166"></span
          ><span style="-aw-bookmark-end:_DV_M168"></span
          ><span style="-aw-bookmark-end:_DV_M169"></span
          ><span style="-aw-bookmark-end:_DV_M170"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >5.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >6 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >All information and/or other </span
        ><span
          style="color:#333333;  font-size:9pt"
          >c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ontent posted on the Sites by the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          service team or by Members or third party partners is supplied for
          information purposes only and shall not under any circumstances be
          construed as legal and/or business advice or a legal opinion. Members
          are encouraged to seek independent professional advice in such
          situations.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span
          style=" font-size:14pt; font-weight:bold"
          >6.  THIRD PARTY VENDORS
        </span>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may engage one or more third party vendors for the purpose of
          completing and fulfilling transaction such as the warehousing and
          logistic service companies,</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          etc. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Some of such third party vendors may be partners of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and thus designated by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to you.  Among such designated partners, you may be required to agree
          and accept the terms and conditions of their se</span
        ><span
          style="color:#333333;  font-size:9pt"
          >rvices.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Notwithstanding the foregoing, for all third party vendors, you
          acknowledge and agree that such third party vendors are engaged at
          your own discretion and cost and that you will not hold </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and our affiliates and agents liable for any losses, damages, claims,
          liabilities, costs or expenses arising from the services of such third
          party vendors. You shall ensure that the third parties comply with
          Privacy Policy</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span
          style=" font-size:14pt; font-weight:bold"
          >7.  CONFIDENTIALITY</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >7</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.1 Confidential Obligations.  You shall keep confidential all
          confidential information provided by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >any buyer or </span
        ><span
          style="color:#333333;  font-size:9pt"
          >by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or any of its affiliates in connection with any Online Transaction or
          the Services.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >7</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.2 Confidential Information.  All information and material provided
          by</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          any buyer or by FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or any of its affiliates will be deemed to be confidential information
          unless such information or material is already in the public domain or
          has subsequently becomes public other than due to your breach of the
          confidential obligations.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >7</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.3 Data Protection and Privacy. You must read the privacy policies of
          Sites which govern the protection and use of personal information of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >buyers and sellers</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          in the possession of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and its affiliates and explains how your personal information may be
          processed in connection with services offered by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Privacy Policy can be accessed at Privacy Policy, for users who access
          or use the Sites relating to the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          platform, and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >7</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.4 Data Protection and Privacy Role of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member shall process personal information of buyers solely to fulfill
          Online Transactions. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >acknowledge</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          that </span
        ><span
          style="color:#333333;  font-size:9pt"
          >it has</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >its</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          own data protection and privacy compliance responsibilities with
          respect to personal information of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >buyers</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or other individuals that the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          processes. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          represent</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and warrant</span
        ><span
          style="color:#333333;  font-size:9pt"
          >s</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, and undertake to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to ensure, that in relation to such personal information </span
        ><span
          style="color:#333333;  font-size:9pt"
          >it is</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and will be fully compliant with all applicable data protection and
          privacy laws, including without limitation maintaining </span
        ><span
          style="color:#333333;  font-size:9pt"
          >its</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          own records of such personal information independently of the platform
          (to the extent required under applicable laws), complying with direct
          marketing laws, and responding to requests by individuals to exercise
          their rights under such laws. Neither </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          nor any of its affiliates is responsible or liable for </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member’s </span
        ><span
          style="color:#333333;  font-size:9pt"
          >fulfilment of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >its </span
        ><span
          style="color:#333333;  font-size:9pt"
          >obligations under applicable data protection and privacy laws. For
          the avoidance of doubt, in relation to such personal information the
          liability and obligations of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and its affiliates under applicable data protection and privacy laws
          are entirely independent of and separate from the liability and
          obligations of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          under such laws. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall have no liability to any</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Member</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          for the compliance, by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or its affiliates, with its or their own obligations under applicable
          data protection or privacy laws, including without limitation in
          relation to the exercise by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >buyers</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or other individuals of their rights regarding their personal
          information processed in connection with any services and products
          provided by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M172"></a
        ><a name="_DV_M173"><span style="-aw-bookmark-end:_DV_M172"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >8</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
        </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >LIMITATION OF LIABILITY</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M174"></a
        ><a name="_DV_M175"><span style="-aw-bookmark-end:_DV_M174"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE IS PROVIDED ON
          AN "AS IS" AND "AS AVAILABLE" BASIS, AND </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FUNPINPIN</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF CONDITION, QUALITY,
          DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR
          FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. ALL SUCH
          WARRANTIES, REPRESENTATIONS, CONDITIONS, UNDERTAKINGS AND TERMS ARE
          HEREBY EXCLUDED.</span
        ><a name="_DV_M176"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M177"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.2 TO THE MAXIMUM EXTENT PERMITTED BY LAW, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FUNPINPIN</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY, ACCURACY,
          RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR CURRENTNESS OF ANY
          INFORMATION PROVIDED ON OR THROUGH THE SITES.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M178"></a
        ><a name="_DV_M179"><span style="-aw-bookmark-end:_DV_M178"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.3 Any material downloaded or otherwise obtained through the access
          to or use of the Sites or Service is done at each Member's sole
          discretion and risk and each Member is solely responsible for any
          damage to its computer system or any loss of data that results from
          the download of any such material. No advice or information, whether
          oral or written, obtained by any Member from </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or through or from the Service shall create any warranty not expressly
          stated in this Agreement.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M180"></a
        ><a name="_DV_M181"><span style="-aw-bookmark-end:_DV_M180"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.4 The Sites may make available to User services or products provided
          by independent third parties.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          No warranty or representation is made with regard to such services or
          products.</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          In no event shall </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          or its affiliates be held liable for any such services or
          products.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M182"></a
        ><a name="_DV_M183"><span style="-aw-bookmark-end:_DV_M182"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.5</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >UNDER NO CIRCUMSTANCES SHALL FUNPINPIN BE HELD LIABLE FOR AN DELAY OR
          FAILURE OR DISRUPTION OF THE SERVICE ACCESSED OR DELIVERED THROUGH THE
          SITES OR THE CREATION OR FULFILMENT OF CONTRACTS RESULTING DIRECTLY OR
          INDIRECTLY FROM ACTS OF NATURE, FORCES OR CAUSES BEYOND ITS REASONABLE
          CONTROL, INCLUDING WITHOUT LIMITATION, INTERNET FAILURES, COMPUTER,
          TELECOMMUNICATIONS OR ANY OTHER EQUIPMENT FAILURES, ELECTRICAL POWER
          FAILURES, STRIKES, LABOR DISPUTES, RIOTS, INSURRECTIONS, CIVIL
          DISTURBANCES, SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOOD, STORMS,
          EXPLOSIONS, ACTS OF GOD, WAR, GOVERNMENTAL ACTIONS, ORDERS OF DOMESTIC
          OR FOREIGN COURTS OR TRIBUNALS OR NON-PERFORMANCE OF THIRD PARTIES OR
          ANY SUSPENSION OR DISRUPTION OF TRANSPORTATION OR BUSINESS OPERATION
          (INCLUDING BUT NOT LIMITED TO DELAYS OR DISRUPTION OF THE RESUMPTION
          OF WORK OR OPERATION ORDERED BY ANY GOVERNMENT AGENCY) IN THE EVENT OF
          A NATIONAL OR REGIONAL SPREAD OF EPIDEMIC OR PANDEMIC.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M184"></a
        ><a name="_DV_M185"><span style="-aw-bookmark-end:_DV_M184"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.6 To the extent permitted by law, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall not be liable for any special, direct, indirect, punitive,
          incidental or consequential damages or any damages whatsoever
          (including but not limited to damages for loss of profits or savings,
          business interruption, loss of information), whether in contract,
          negligence, tort or otherwise or any other damages resulting from any
          of the following:</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M186"></a
        ><a name="_DV_M187"><span style="-aw-bookmark-end:_DV_M186"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >a) the use or the inability to use the Service;</span
        ><a name="_DV_M188"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M189"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >b) any defect in goods, samples, data, information or services
          purchased or obtained from a Member or a third-party service provider
          through the Sites;</span
        ><a name="_DV_M190"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M191"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >c) unauthorized access by third parties to data or private
          information of any Member;</span
        ><a name="_DV_M192"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M193"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >d) statements or conduct of any user of the Sites; or</span
        ><a name="_DV_M194"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M195"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >e) any other matter relating to the Sites or Service however arising,
          including negligence.</span
        ><a name="_DV_M196"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M197"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >8</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.7 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >NOTWITHSTANDING ANY OF THE FOREGOING PROVISIONS, THE AGGREGATE
          LIABILITY OF FUNPINPIN, ITS EMPLOYEES, AGENTS, AFFILIATES, AFFILIATES
          OR ANYONE ACTING ON ITS BEHALF WITH RESPECT TO EACH MEMBER FOR ALL
          CLAIMS ARISING FROM THE ACCESS TO OR USE OF THE SERVICE OR THE SITES
          DURING ANY CALENDAR YEAR SHALL BE LIMITED TO HK$1000</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. The preceding sentence shall not preclude the requirement by the
          Member to prove actual damages. All claims arising from the use of the
          Service must be filed within either one (1) year from the date the
          cause of action arose or such longer period as prescribed under the
          applicable law governing this Agreement.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M198"></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >9. </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >PRIVACY</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M200"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >Please review the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Privacy Policy</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          to know how we use and protect your personal information when you use
          Services of the relevant Site</span
        ><a name="_DV_M201"></a
        ><a name="_DV_M202"><span style="-aw-bookmark-end:_DV_M201"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M203"></a><a name="_DV_M204"></a><a name="_DV_M205"></a
        ><a name="_DV_M206"></a><a name="_DV_M207"></a
        ><a name="_DV_M209"
          ><span style="-aw-bookmark-end:_DV_M203"></span
          ><span style="-aw-bookmark-end:_DV_M204"></span
          ><span style="-aw-bookmark-end:_DV_M205"></span
          ><span style="-aw-bookmark-end:_DV_M206"></span
          ><span style="-aw-bookmark-end:_DV_M207"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >10</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
          INTELLECTUAL PROPERTY RIGHTS</span
        ><a name="_DV_M210"></a>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M211"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >10</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.1</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          is the sole owner or lawful licensee of all the rights to the Service.
          The Service embodies trade secrets and intellectual property rights
          protected under worldwide copyright and other laws. All title,
          ownership and intellectual property rights in the Service shall remain
          with </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, its affiliates or licensors, as the case may be. All rights not
          otherwise claimed under this Agreement or by </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          are hereby reserved.</span
        ><a name="_DV_M212"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M213"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >10</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          "</span
        ><span
          style="color:#333333;  font-size:9pt"
          >FUNPINPIN</span
        ><span
          style="color:#333333;  font-size:9pt"
          >"</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and related icons and logos are protected under applicable copyright,
          trademark and other proprietary rights laws. The unauthorized copying,
          modification, use or publication of these marks is strictly
          prohibited.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span
          style=" font-size:14pt; font-weight:bold"
          >11.  NOTICES</span
        >
      </h1>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >Except as explicitly stated otherwise, legal notices shall be served
          on you by sending notices to the email address in your latest me</span
        ><span
          style="color:#333333;  font-size:9pt"
          >mbership profile on the Sites. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Notice shall be deemed given 24 hours after email is sent, unless </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin is</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          notified that</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          the email address is invalid. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Alternatively, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          may give you legal notices by mail to the address in your latest
          membership profile in which case the notice shall be deemed given five
          d</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ays after the date of mailing. </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Except as explicitly stated otherwise, legal notices shall be served
          on </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          by sending the notices to </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          personally, by courier or certified mail at </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FLAT/RM A 12/F KIU FU COMMERCIAL BLDG, 300 LOCKHART ROAD, WAN CHAI
          HK</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, for the attention of the Legal Departmen</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <a name="_DV_M215"></a
        ><a name="_DV_M216"><span style="-aw-bookmark-end:_DV_M215"></span></a
        ><span
          style=" font-size:14pt; font-weight:bold"
          >12</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >.  GOVERNING LAW</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
          AND</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
          JURISDICTION</span
        >
      </h1>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="color:#333333;  font-size:9pt"
          >THIS AGREEMENT SHALL BE GOVERNED BY LAWS OF THE HONG KONG SPECIAL
          ADMINISTRATIVE REGION ("HONG KONG") WITHOUT REGARD TO ITS CONFLICT OF
          LAW PROVISIONS; </span
        ><span
          style="color:#333333;  font-size:9pt"
          >ALL DISPUTES ARISING FROM THE IMPLEMENTATION OF OR IN CONNECTION WITH
          THIS AGREEMENT, INCLUDING QUESTIONS REGARDING ITS EXISTENCE, VALIDITY
          OR TERMINATION, SHALL BE SETTLED, REFERRED TO AND FINALLY RESOLVED IN
          BEIJING ARBITRATION COMMISSION, UNDER ITS RULES OF ARBITRATION FOR THE
          TIME BEING IN FORCE.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >THE SEAT OF ARBITRATION IS BEIJING</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <h1
        style="font-size:14pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span
          style=" font-size:14pt; font-weight:bold"
          >13</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >.</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
        >
        </span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style=" font-size:14pt; font-weight:bold"
          >GENERAL</span
        ><a name="_DV_M217"></a>
      </h1>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M218"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.1</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          This Agreement and the Terms of Use constitute the entire agreement
          between the Member and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          with respect to and governs the use of the Service, superseding any
          prior written or oral agreements in relation to the same subject
          matter herein.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M219"></a
        ><a name="_DV_M220"><span style="-aw-bookmark-end:_DV_M219"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.2</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and the Member are independent contractors, and no agency,
          partnership, joint venture, employee-employer or franchiser-franchisee
          relationship is intended or created by this Agreement.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M221"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.3 If any provision of this Agreement is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced.</span
        >
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M222"></a
        ><a name="_DV_M223"><span style="-aw-bookmark-end:_DV_M222"></span></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.4 Headings are for reference purposes only and in no way define,
          limit, construe or describe the scope or extent of such section.</span
        ><a name="_DV_M224"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M225"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.5 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >'s failure to enforce any right or failure to act with respect to any
          breach by a Member under this Agreement </span
        ><span
          style="color:#333333;  font-size:9pt"
          >shall not be deemed as a w</span
        ><span
          style="color:#333333;  font-size:9pt"
          >aive</span
        ><span
          style="color:#333333;  font-size:9pt"
          >r</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >that right nor waive </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >'s right to act with respect with subsequent or similar
          breaches.</span
        ><a name="_DV_M226"></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <a name="_DV_M227"></a
        ><span
          style="color:#333333;  font-size:9pt"
          >13</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.6 </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          shall have the right to assign this Agreement (including all of its
          rights, titles, benefits, interests, and obligations and duties in
          this Agreement) to any person or entity (including any affiliates of </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >). The Member may not assign, in whole or part, this Agreement to any
          person or entity.</span
        ><a name="_DV_M228"></a><a name="_DV_M229"></a><a name="_DV_M233"></a
        ><a name="_DV_M234"
          ><span style="-aw-bookmark-end:_DV_M228"></span
          ><span style="-aw-bookmark-end:_DV_M229"></span
          ><span style="-aw-bookmark-end:_DV_M233"></span
        ></a>
      </p>
      <p style="margin:0pt; text-align:justify">
        <span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        >
      </p>
    </div>
  </pageBack>
</template>

<script>
import pageBack from "@/components/basic/pageBack.vue";
export default {
  components: {
    pageBack
  }
};
</script>
<style lang="scss" scoped>
.main {
  padding: 40px;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC !important;
}
</style>
